// @flow
import React from 'react';
import { navigate } from 'gatsby';
import { Box } from '@material-ui/core';
import Layout from '../../../components/Layout/Layout';
import Hero from '../../../components/Merchant/shared/Hero';

const Merchant = () => {
  if (typeof window !== `undefined`) {
    navigate('/merchant/industry/goods/');
  }

  return (
    <Layout isMerchant>
      <Box minHeight="100vh">
        <Hero title="業種別の使い方" subTitle="物販" />
      </Box>
    </Layout>
  );
};

// $FlowFixMe
export default Merchant;
